import React from "react";

function Dashboard() {
  return (
    <div className="d-flex justify-content-center">
      <h2 style={{marginTop: "200px"}}>Coming Soon...</h2>
    </div>
  );
}

export default Dashboard;
