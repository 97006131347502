import React from "react";

function Footer() {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="text-muted text-sm">copyright@HRMS2024</div>
    </div>
  );
}

export default Footer;
